.inner-container {
    padding: 20px;
}

/* Grid layout changed to flex */
.grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
}

/* Set fixed width for items */
.bg-white {
    background-color: white;
    width: 250px;
    flex-shrink: 0;
}

.rounded-lg {
    border-radius: 0.5rem;
}

.shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.overflow-hidden {
    overflow: hidden;
}

.cursor-pointer {
    cursor: pointer;
}

/* Image container */
.relative {
    position: relative;
}

.w-full {
    width: 100%;
}

.pt-\[100\%\] {
    padding-top: 100%;
}

/* Image */
.absolute {
    position: absolute;
}

.top-0 {
    top: 0;
}

.left-0 {
    left: 0;
}

.h-full {
    height: 100%;
}

.object-cover {
    object-fit: cover;
}

/* Navigation buttons */
.left-2 {
    left: 0.5rem;
}

.right-2 {
    right: 0.5rem;
}

.top-1\/2 {
    top: 50%;
}

.transform {
    transform: translateY(-50%);
}

.bg-black\/50 {
    background-color: rgba(0, 0, 0, 0.5);
}

.text-white {
    color: white;
}

.rounded-full {
    border-radius: 9999px;
}

.p-2 {
    padding: 0.5rem;
}

/* Content area */
.p-4 {
    padding: 1rem;
}

.text-lg {
    font-size: 1.125rem;
}

.font-semibold {
    font-weight: 600;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.text-xl {
    font-size: 1.25rem;
}

.font-bold {
    font-weight: 700;
}

.text-green-600 {
    color: rgb(22, 163, 74);
}

.text-sm {
    font-size: 0.875rem;
}

.text-gray-600 {
    color: rgb(75, 85, 99);
}

.mb-1 {
    margin-bottom: 0.25rem;
}

/* Contact information handling */
.text-gray-600 p {
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

/* Pagination */
.flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.mt-8 {
    margin-top: 2rem;
}

.gap-4 {
    gap: 1rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.bg-blue-500 {
    background-color: rgb(233, 114, 0);
}

.disabled\:bg-gray-300:disabled {
    background-color: rgb(209, 213, 219);
}

.mr-2 {
    margin-right: 0.5rem;
}

.ml-2 {
    margin-left: 0.5rem;
}


