.marketplace-detail {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

/* Image carousel */
.image-carousel {
    margin-bottom: 2rem;
}

.image-container-marketplace {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    background-color: #f3f4f6;
    border-radius: 8px;
    overflow: hidden;
}

.main-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.nav-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.prev-button {
    left: 16px;
}

.next-button {
    right: 16px;
}

/* Thumbnail strip */
.thumbnail-strip {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    overflow-x: auto;
    padding: 8px 0;
}

.thumbnail {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.thumbnail:hover {
    opacity: 0.9;
}

.thumbnail.active {
    opacity: 1;
    border: 2px solid #2563eb;
}

/* Item details */
.item-details {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #1f2937;
}

.price {
    font-size: 1.5rem;
    font-weight: 700;
    color: #059669;
    margin-bottom: 1rem;
}

.posted-date {
    color: #6b7280;
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
}

.description {
    margin-bottom: 2rem;
    line-height: 1.6;
    color: #374151;
    white-space: pre-wrap;
}

.contact-info {
    padding-top: 1.5rem;
    border-top: 1px solid #e5e7eb;
}

.contact-info h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #1f2937;
}

.contact-row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #4b5563;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .title {
        font-size: 1.5rem;
    }

    .price {
        font-size: 1.25rem;
    }

    .nav-button {
        width: 32px;
        height: 32px;
    }
}

/* Make sure contact info wraps properly */
.contact-row span:last-child {
    word-break: break-word;
    overflow-wrap: break-word;
}
