body, input, textarea {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #e97200;
}

input:focus + .slider {
  box-shadow: 0 0 1px #e97200;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.ReactModalPortal {
  position: relative;
  z-index: 200 !important;
}
.fc .fc-button-primary { color: #FFF; background-color: #e97200 !important; border-color: #FFFFFF !important; }
/* Add these animations to your CSS file */
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideBackIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.submenu-container {
  position: relative;
  width: 100%;
}

.submenu-container.slide-in {
  animation: slideIn 0.3s forwards;
}

.submenu-container.slide-out {
  animation: slideOut 0.3s forwards;
}

.submenu-container.slide-back-in {
  animation: slideBackIn 0.3s forwards;
}
.magazine-gallery-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.magazine-gallery-title {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: 600;
}

/* Filter styles removed */

.magazine-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 30px;
  justify-content: center;
}

.magazine-item {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
}

.magazine-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.magazine-cover-container {
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  aspect-ratio: 3/4;
}

.magazine-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;
}

.magazine-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(233, 114, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.magazine-item:hover .magazine-overlay {
  opacity: 1;
}

.magazine-item:hover .magazine-cover {
  transform: scale(1.08);
}

.view-text {
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.view-text:hover {
  background-color: white;
  color: #e97200;
}

.magazine-info {
  padding: 15px 10px;
  text-align: center;
  background-color: #f9f9f9;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.magazine-title {
  font-size: 15px;
  margin: 0 0 8px 0;
  line-height: 1.3;
  color: #333;
  font-weight: 600;
}

.magazine-date {
  font-size: 13px;
  color: #777;
  margin: 0;
}

.magazine-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  text-align: center;
}

.loading-spinner {
  border: 4px solid rgba(233, 114, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #e97200;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.magazine-error, .no-magazines {
  text-align: center;
  padding: 40px 20px;
  font-size: 18px;
  color: #666;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-top: 20px;
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .magazine-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 25px;
  }
}

@media (max-width: 600px) {
  .magazine-gallery-title {
    font-size: 24px;
  }

  .magazine-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 20px;
  }

  .magazine-title {
    font-size: 14px;
  }

  .magazine-date {
    font-size: 12px;
  }

  .view-text {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .magazine-gallery-container {
    padding: 20px 15px;
  }

  .magazine-grid {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 15px;
  }

  .magazine-info {
    padding: 10px 5px;
  }

  .magazine-title {
    font-size: 12px;
    margin-bottom: 4px;
  }
}
/* Error container styles */
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  border-left: 4px solid #e97200;
  margin: 20px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.error-icon {
  font-size: 48px;
  color: #e97200;
  margin-bottom: 15px;
}

.error-title {
  font-size: 22px;
  color: #80561b;
  margin-bottom: 15px;
  font-weight: 600;
}

.error-message {
  color: #333;
  margin-bottom: 20px;
  line-height: 1.6;
  max-width: 450px;
}

.error-button {
  background-color: #e97200;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.error-button:hover {
  background-color: #ff8c2a;
}

@media (max-width: 768px) {
  .error-container {
    padding: 20px 15px;
  }

  .error-icon {
    font-size: 36px;
  }

  .error-title {
    font-size: 18px;
  }
}
/* Success Message */
.success-message {
  background-color: #dff0d8;
  color: #3c763d;
  border-left: 4px solid #5cb85c;
  padding: 12px 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  animation: fadeInOut 3s forwards;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

/* Profile Section */
.employee-profile {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin-bottom: 30px;
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
}

.profile-photo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 25px;
  border: 3px solid #e97200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo-placeholder {
  width: 100%;
  height: 100%;
  background-color: #e97200;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
}

.profile-title h2 {
  margin: 0 0 5px 0;
  color: #333;
  font-size: 24px;
}

.employee-position {
  color: #e97200;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.employee-department {
  color: #80561b;
  margin: 0;
}

.profile-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.profile-edit-button {
  background-color: #e97200;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.profile-edit-button:hover {
  background-color: #ff8c2a;
}

/* Profile Details */
.profile-details {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.detail-group {
  flex: 1;
  min-width: 250px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.detail-group h3 {
  color: #80561b;
  font-size: 18px;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 2px solid #f0f0f0;
}

.detail-row {
  display: flex;
  margin-bottom: 10px;
}

.detail-label {
  font-weight: 600;
  min-width: 120px;
  color: #666;
}

.detail-value {
  color: #333;
}

/* Employee Self-Service Styles */

/* Success Message */
.success-message {
  background-color: #dff0d8;
  color: #3c763d;
  border-left: 4px solid #5cb85c;
  padding: 12px 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  animation: fadeInOut 3s forwards;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

/* Profile Section */
.employee-profile {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin-bottom: 30px;
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
}

.profile-photo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 25px;
  border: 3px solid #e97200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo-placeholder {
  width: 100%;
  height: 100%;
  background-color: #e97200;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
}

.profile-title h2 {
  margin: 0 0 5px 0;
  color: #333;
  font-size: 24px;
}

.employee-position {
  color: #e97200;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.employee-department {
  color: #80561b;
  margin: 0;
}

.profile-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.profile-edit-button {
  background-color: #e97200;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.profile-edit-button:hover {
  background-color: #ff8c2a;
}

/* Profile Details */
.profile-details {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.detail-group {
  flex: 1;
  min-width: 250px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.detail-group h3 {
  color: #80561b;
  font-size: 18px;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 2px solid #f0f0f0;
}

.detail-row {
  display: flex;
  margin-bottom: 10px;
}

.detail-label {
  font-weight: 600;
  min-width: 120px;
  color: #666;
}

.detail-value {
  color: #333;
}

/* Form Styles */
.profile-form {
  background-color: #f9f9f9;
  padding: 25px;
  border-radius: 6px;
  margin-bottom: 30px;
  border-left: 4px solid #e97200;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  min-width: 200px;
  padding: 8px;
  margin-right: 15px;
  margin-bottom: 25px;
}

.form-group:last-child {
  margin-right: 0;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #666;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 5px;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #e97200;
  outline: none;
  box-shadow: 0 0 3px rgba(233, 114, 0, 0.3);
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.save-button {
  background-color: #e97200;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  margin-left: 10px;
}

.save-button:hover {
  background-color: #ff8c2a;
}

.cancel-button {
  background-color: #ccc;
  color: #333;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.cancel-button:hover {
  background-color: #ddd;
}

/* Vehicles Section */
.vehicles-section {
  margin-top: 30px;
  border-top: 2px solid #f0f0f0;
  padding-top: 20px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section-header h3 {
  color: #80561b;
  margin: 0;
}

.add-vehicle-button {
  background-color: #e97200;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.add-vehicle-button:hover {
  background-color: #ff8c2a;
}

.add-vehicle-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.vehicle-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 20px;
  border-left: 4px solid #e97200;
}

.vehicle-form h4 {
  margin-top: 0;
  color: #e97200;
  margin-bottom: 15px;
}

.vehicles-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.vehicle-card {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
}

.vehicle-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.vehicle-info h4 {
  margin: 0 0 10px 0;
  color: #333;
}

.vehicle-details {
  display: flex;
  gap: 15px;
}

.vehicle-color, .vehicle-plate {
  font-size: 13px;
  padding: 3px 8px;
  border-radius: 12px;
  background: #f1f1f1;
  color: #666;
}

.vehicle-plate {
  font-weight: 600;
}

.vehicle-actions {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.edit-vehicle-button, .delete-vehicle-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s;
}

.edit-vehicle-button {
  background-color: #f0ad4e;
  color: white;
}

.edit-vehicle-button:hover {
  background-color: #ec971f;
}

.edit-vehicle-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.delete-vehicle-button {
  background-color: #d9534f;
  color: white;
}

.delete-vehicle-button:hover {
  background-color: #c9302c;
}

.delete-vehicle-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.no-vehicles {
  grid-column: 1 / -1;
  text-align: center;
  color: #777;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 6px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .profile-header {
    flex-direction: column;
    text-align: center;
  }

  .profile-photo {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .form-row {
    flex-direction: column;
  }

  .form-group {
    margin-right: 0;
  }

  .profile-details {
    flex-direction: column;
  }

  .detail-group {
    margin-right: 0;
  }

  .vehicles-list {
    grid-template-columns: 1fr;
  }
}
/* Adding additional styles to the existing employee self-service styles */

/* Form section titles */
.form-section-title {
  color: #e97200;
  font-size: 18px;
  margin: 25px 0 15px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #f0f0f0;
}

.form-section-title:first-of-type {
  margin-top: 0;
}

/* Checkbox styling */
.checkbox-group {
  display: flex;
  align-items: center;
  padding-top: 12px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.checkbox-text {
  user-select: none;
}

/* Vehicle tag styling */
.vehicle-tag {
  font-size: 13px;
  padding: 3px 8px;
  border-radius: 12px;
  background: #e0f0e0;
  color: #388e3c;
  font-weight: 500;
}

/* Read-only birthdate display */
.birthdate-display {
  color: #555;
  font-style: italic;
}

/* Address display */
.address-display {
  margin-top: 5px;
  color: #666;
  font-size: 14px;
}

/* Neighborhood badge */
.neighborhood-badge {
  display: inline-block;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 2px 10px;
  font-size: 13px;
  color: #666;
  margin-top: 5px;
}

/* Resident number badge */
.resident-number {
  background-color: #e97200;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  display: inline-block;
  margin-left: 10px;
}

/* Enhanced photo styling for residents */
.profile-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.profile-photo:hover img {
  transform: scale(1.05);
}

/* Preference section styling */
.preferences-section {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.preference-title {
  font-weight: 600;
  margin-bottom: 10px;
  color: #555;
}

/* Vehicle card enhancements */
.vehicle-card {
  border-left: 3px solid #e97200;
  transition: all 0.3s ease;
}

.vehicle-card:hover {
  border-left-width: 5px;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .checkbox-group {
    padding-top: 0;
  }

  .form-section-title {
    font-size: 16px;
  }
}
/* Document Gallery Styles for Catie Interface */
.documents-gallery-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.gallery-section {
  margin-bottom: 40px;
}

.gallery-section-title {
  color: #80561b;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #f0f0f0;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 25px;
  justify-content: center;
}

.gallery-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 15px 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.gallery-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.folder-item:hover {
  background-color: rgba(233, 114, 0, 0.1);
}

.document-item:hover {
  background-color: rgba(233, 114, 0, 0.1);
}

.item-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

.item-icon {
  font-size: 50px;
  transition: transform 0.3s ease;
}

.folder-icon {
  color: #e97200;
}

.document-icon {
  color: #e97200;
}

.gallery-item:hover .item-icon {
  transform: scale(1.1);
}

.item-name {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  word-break: break-word;
  max-width: 100%;
  line-height: 1.3;
}

.no-content-message {
  text-align: center;
  padding: 50px 20px;
  color: #666;
  background-color: #f9f9f9;
  border-radius: 8px;
  font-size: 16px;
  border-left: 4px solid #e97200;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 20px;
  }

  .item-icon-container {
    width: 60px;
    height: 60px;
  }

  .item-icon {
    font-size: 40px;
  }

  .item-name {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 15px;
  }

  .documents-gallery-container {
    padding: 20px 15px;
  }

  .gallery-section-title {
    font-size: 20px;
  }

  .item-icon-container {
    width: 50px;
    height: 50px;
  }

  .item-icon {
    font-size: 35px;
  }

  .item-name {
    font-size: 12px;
  }
}
/* Directory Component Styles */

.directory-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Search Section */
.search-wrapper {
  margin-bottom: 1.5rem;
}

.search-container {
  position: relative;
  margin-bottom: 1.5rem;
}

.search-input {
  width: 100%;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: var(--defaultColor, #e97200);
  box-shadow: 0 2px 8px rgba(233, 114, 0, 0.2);
}

.search-input::placeholder {
  color: #aaa;
}

/* Filter Options */
.filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  justify-content: center;
  margin: 1rem 0;
}

.filter-option {
  display: flex;
  align-items: center;
}

.directory-radio {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.directory-radio-label {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.9rem;
  background-color: #f1f1f1;
  transition: all 0.2s ease;
  display: inline-block;
}

.directory-radio:checked + .directory-radio-label {
  background-color: var(--defaultColor, #e97200);
  color: #fff;
}

.directory-radio-label:hover {
  background-color: #e0e0e0;
}

.directory-radio:checked + .directory-radio-label:hover {
  background-color: var(--defaultColor, #e97200);
  opacity: 0.9;
}

/* Dropdown Select Styles */
.select-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.directory-select {
  width: 100%;
}

/* Search Button */
.directory-search-button {
  margin-top: 0.5rem;
  padding: 0.75rem 2rem;
  background-color: var(--defaultColor, #e97200);
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(233, 114, 0, 0.2);
  align-self: center;
}

.directory-search-button:hover {
  background-color: #c96100;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(233, 114, 0, 0.3);
}

.directory-search-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 6px rgba(233, 114, 0, 0.3);
}

/* Directory Results Section */
.directory-results {
  margin-top: 2rem;
  border-top: 1px solid #eee;
  padding-top: 1.5rem;
}

/* Emergency Numbers */
.emergency-numbers {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
}

.emergency-number-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  text-align: center;
}

.emergency-number-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  background-color: rgba(233, 114, 0, 0.05);
}

.emergency-name {
  font-weight: 600;
  color: #555;
  margin-bottom: 0.5rem;
}

.emergency-number {
  font-size: 1.1rem;
  color: var(--defaultColor, #e97200);
  font-weight: 500;
}

/* Bottom Bar */
.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
  background-color: var(--defaultColor, #e97200);
  color: white !important;
  z-index: 10;
}
.bottom-bar a{
  color: white !important;
}
.bottom-bar-number {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.bottom-bar-number:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

/* PDF Options Popup */
.pdf-options {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.pdf-section {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 1rem;
  background-color: #f9f9f9;
}

.pdf-section-title {
  text-align: center;
  font-weight: 600;
  color: #555;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
}

.pdf-checkboxes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.pdf-checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.pdf-checkbox {
  margin-right: 0.5rem;
}

.pdf-checkbox-label {
  font-size: 0.9rem;
  color: #555;
}

.pdf-generate-button {
  background-color: var(--defaultColor, #e97200);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
  width: 100%;
}

.pdf-generate-button:hover {
  background-color: #c96100;
}

.pdf-campus-section {
  margin-top: 1rem;
  text-align: center;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .select-wrapper {
    flex-direction: row;
    align-items: flex-end;
  }

  .directory-select {
    flex: 1;
  }

  .directory-search-button {
    margin-top: 0;
    height: fit-content;
  }
}

@media (max-width: 767px) {
  .filter-container {
    justify-content: space-between;
  }

  .emergency-numbers {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }

  .bottom-bar {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .bottom-bar-number {
    flex: 1;
    min-width: 120px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .directory-container {
    padding: 1rem;
  }

  .search-input {
    padding: 0.75rem 1rem;
  }

  .filter-container {
    gap: 0.5rem;
  }

  .directory-radio-label {
    padding: 0.4rem 0.75rem;
    font-size: 0.8rem;
  }

  .emergency-numbers {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .pdf-checkboxes {
    grid-template-columns: 1fr;
  }
}

/* Directory Results Display Styles */

.directory-results-container {
  margin-top: 1.5rem;
}

.directory-section {
  margin-bottom: 2.5rem;
}

.section-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid #f0f0f0;
  position: relative;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 60px;
  height: 2px;
  background-color: var(--defaultColor, #e97200);
}

.directory-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
}

/* Card Styles */
.directory-card {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.directory-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.directory-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--defaultColor, #e97200);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.directory-card:hover::before {
  transform: scaleX(1);
}

.card-image {
  display: flex;
  justify-content: center;
  padding: 1.25rem 1.25rem 0.5rem;
}

.card-content {
  padding: 1rem 1.25rem 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.card-name {
  font-size: 1.1rem;
  margin: 0 0 0.5rem;
  color: #333;
  font-weight: 600;
  line-height: 1.3;
}

.card-detail, .card-department, .card-position, .card-neighborhood {
  font-size: 0.9rem;
  margin-bottom: 0.35rem;
  color: #666;
  line-height: 1.4;
}

.card-department {
  color: var(--defaultColor, #e97200);
  font-weight: 500;
}

.card-position {
  font-style: italic;
}

.card-neighborhood {
  background-color: #f5f5f5;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

/* Campus Card Specific Styles */
.campus-card {
  padding: 1.25rem;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
}

.campus-card:hover {
  background-color: rgba(233, 114, 0, 0.05);
}

.campus-link {
  text-decoration: none;
  color: inherit;
  display: block;
  text-align: center;
}

.campus-name {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #444;
}

.campus-number {
  font-size: 1.3rem;
  color: var(--defaultColor, #e97200);
  font-weight: 600;
}

/* No Results Styling */
.no-results {
  background-color: #f9f9f9;
  padding: 1.5rem;
  text-align: center;
  border-radius: 8px;
  color: #777;
  font-style: italic;
  border-left: 3px solid #ddd;
}

/* Team Member Card Specific Styling */
.team-card .card-content {
  background-color: #fdfdfd;
}

/* Resident Card Specific Styling */
.resident-card .card-content {
  background-color: #fdfdfd;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .directory-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 1.25rem;
  }
}

@media (max-width: 768px) {
  .directory-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
  }

  .card-content {
    padding: 0.75rem 1rem 1.25rem;
  }

  .card-name {
    font-size: 1rem;
  }

  .card-detail, .card-department, .card-position {
    font-size: 0.85rem;
  }

  .section-title {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .directory-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .directory-card {
    max-width: 100%;
  }

  .section-title {
    font-size: 1.2rem;
  }
}
