.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.fancyList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.fancyList li {
  padding: 10px;
  background-color: #f1f1f1;
  border-bottom: 1px solid #ddd;
}

.fancyList li:hover {
  background-color: #ddd;
}

.fancyList li h2 {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.fancyList li p {
  font-size: 1em;
  color: #666;
}
.fancy-line {
  border: 0; /* Remove default border */
  height: 2px; /* Set line thickness */
  background-image: linear-gradient(to right, #FFA500, #FF4500); /* Fancy gradient effect */
}

.survey-container {
  display: flex;
  flex-direction: column;
  width: 60%; /* adjust the width as needed */
  margin: auto; /* centers the form horizontally */
  padding: 20px;
  background-color: #fafafa; /* light gray */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.15); /* subtle box shadow */
}

.survey-container {
  display: flex;
  flex-direction: column;
}

.survey-container input,
.survey-container textarea,
.survey-container button {
  margin: 10px 0;
  font-size: 16px;
}
.survey-container input[type=text], textarea{
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}
.survey-container input[type=text]:focus, textarea:focus{
  border: 2px solid #FFA500;
}
.survey-container button {
  cursor: pointer;
  background-color: #FFA500; /* bootstrap primary color */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
}



.survey-radio {
  /* Hide the default radio buttons */
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-label, .checkbox-label {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

/* This creates a custom radio button */
.radio-label::before {
  content: '';
  /* Use margin to push label text to the right */
  margin-right: 10px;
  /* Size of the custom radio button */
  width: 20px;
  height: 20px;
  border: 1px solid #FFA500; /* bootstrap primary color */
  border-radius: 50%; /* Makes it a circle */
  display: inline-block;
}
[type='checkbox'] + .checkbox-label::before {
  content: '';
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border: 1px solid #FFA500;
  display: inline-block;
}

.survey-radio:checked + .checkbox-label::before {
  background-color: #FFA500;
  content: "";
  position: relative;
}
.survey-radio:checked + .radio-label::before {
  background-color: #FFA500;
  content: "";
  position: relative;
}

/* If the radio is checked, insert a smaller circle inside of it */
.survey-radio:checked + .radio-label::before {
  background-color: #FFA500;
  content: "";
  position: relative;
}

.survey-radio:checked + .radio-label::after {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 3px;
  top: 3px;
  content: "";
  display: none;
  background: white;
}
.survey-button-container{
  padding: 10px 10px 10px 10px;
}
.survey-question-container{
  padding-bottom: 15px;
  margin-top: 15px;
  border-top: 1px solid #FFA500;
}
.question{
  font-size: 1.2em;
  font-weight: bold;
  padding-bottom: 5px;
}
.search-button{background-color: transparent;
  border: none;
  color: #FFA500;
  font-size: 18px;
  cursor: pointer;
}
.bottom-view {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 100px;
  background-color: rgba(78,68,68, 0.69);
}
.post {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
}

.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.post-image {
  width: 100%;
  height: auto;
  margin-top: 10px;
}

.post-reactions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

button {
  background-color: #e97200;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.createPostForm {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
}

.postInput {
  min-height: 50px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #cccccc;
  margin-bottom: 10px;
  resize: none;
}

.submitPost {
  border: none;
  background-color: #e97200;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: #4267b2;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown-menu li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.workorder {
  width: 100%;
  border-collapse: collapse;
}

.workorder th,
.workorder td {
  padding: 8px;
  cursor: pointer;
  text-align: left;
  border: none;
}

.workorder tbody:nth-child(even) {
  background-color: #f2f2f2;
}

.workorder th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #e97200;
  color: white;
}
.slide-pane {
  max-width: 50% !important; /* Change this to the value you want */
}
.giftcard {
  border: 1px solid #e97200;
  margin: 20px;
  padding: 10px;
  border-radius: 20px;
  width: 125px;
  cursor: pointer;
}
.selected {
  border: 2px solid #e97200 !important;
  font-weight: bold;
}
.codes td, .codes th {
  text-align: left;
  padding-bottom: 15px;
}
/* Menu container styles */
.menu-container {
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
}

.menu-container h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
}

/* Menu list styles */
.menu-list {
  margin: 0;
  padding: 0;
}

.menu-item {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  background-color: #f1f1f1;
}

.menu-item:hover {
  background-color: #ddd;
}

.menu-item:last-child {
  border-bottom: none;
}

.menu-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-item-title {
  font-weight: bold;
  color: #333;
}

/* Action buttons */
.menu-item-actions {
  display: flex;
  gap: 10px;
}

.menu-action-button {
  background-color: #e97200;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.edit-button {
  background-color: #4267b2;
}

.delete-button {
  background-color: #dc3545;
}

/* Message for no items */
.no-items-message {
  font-style: italic;
  color: #666;
}
/* Section headers */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid #f1f1f1;
}

.section-header h3 {
  margin: 0;
  color: #333;
  font-size: 1.4em;
  font-weight: 600;
}

.add-new-button {
  background-color: #e97200;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.add-new-button:hover {
  background-color: #d36700;
}

/* Table styling */
.admin-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.admin-table th {
  background-color: #f8f8f8;
  color: #555;
  font-weight: 600;
  padding: 12px 15px;
  text-align: left;
  border-bottom: 2px solid #e97200;
}

.admin-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #eee;
}

.admin-table tr:hover {
  background-color: #f9f9f9;
}

/* Edit link styling */
.edit-link {
  color: #e97200;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  transition: color 0.2s;
}

.edit-link:hover {
  color: #d36700;
}

/* Spacing between sections */
.section-divider {
  margin: 30px 0;
}

/* Empty space placeholder */
.empty-space {
  width: 24px;
}
