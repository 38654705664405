/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 25px;
    height: 20px;
    left: 36px;
    top: 50%;
    margin-top: -5px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #FFF;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: black;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #fff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    width: 100%;
    cursor: pointer;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
/* Individual item */
.bm-item {
    display: flex;
    color: #80561b;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
}

.bm-item:hover {
    color: #e97200;
}

.user-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    border: 3px solid #333;
    cursor: pointer;
}

.image-container img {
    width: 80px;
    height: 80px;
}

/* Submenu Related Styles */

/* Menu items styling - integrate with your existing styles */
.menu-item-main {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    position: relative;
    color: #80561b;
    transition: color 0.2s;
    text-align: left;
}

.menu-item-main:hover {
    color: #e97200;
}

.icon-wrapper {
    width: 25px;
    padding-right: 5px;
    text-align: center;
    display: inline-block;
}

.submenu-indicator {
    margin-left: auto;
}

/* Submenu container with animations */
.submenu-container {
    width: 100%;
    position: relative;
    transition: transform 0.3s;
}

/* Animation classes */
.slide-in {
    animation: slideInRight 0.3s forwards;
}

.slide-out {
    animation: slideOutRight 0.3s forwards;
}

.slide-back-in {
    animation: slideInLeft 0.3s forwards;
}

/* Animations */
@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutRight {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes slideInLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}
